.subheader{
  height: @subHeaderHeight;
  background: @white;

  .container{
    height: 100%;
  }

  a, .like--a-link{
    color: @textGrey;
    display: flex;
    width: auto;
    font-size: 1.125rem;
    padding: 13px 17px;
    align-items: baseline;
    height: @subHeaderHeight;
    text-decoration: none;
    .interactionTransition();

    &:hover{
      color: @red;
    }

    &.is--active{
      color: @red;
    }

    &.is--off{
      color: @medLightGrey;
      cursor: not-allowed;
    }
  }

  .subheader--right{
    display: flex;
    justify-content: right;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;


    li{
      list-style-type: none;
      position: relative;
      margin: 0;
      padding: 0;
    }

  }

  @media @mobile{
    display: none;
  }
}
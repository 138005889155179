#scrolltop{
  position: fixed;
  z-index: 700;
  right: 1rem;
  bottom: 1rem;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @red;
  color: @white;
  opacity: 0.3;
  -webkit-transform: translate3d(70px,0,0);
  transform: translate3d(70px,0,0);
  .interactionTransition();

  @media @mobile{
    width: 44px;
    height: 44px;
    -webkit-transform: translate3d(60px,0,0);
    transform: translate3d(60px,0,0);
  }

  svg{
    width: 24px;
    height: auto;
    fill: @white;
  }
}

.scrolled #scrolltop{
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);

  @media @mobile{
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
}
@media @minTabletLandscape{

    main .pure-g{
      margin-bottom: @colBtmSpacerMobile;

      .pure-g{
        margin-bottom: 0;
      }
    }

    // col 33
    .dgm-cols-33{
      .dgm-col-33L{
        .frame {
          margin-right: 1rem;
        }
      }

      .dgm-col-33C{
        .frame {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }

      .dgm-col-33R{
        .frame {
          margin-left: 1rem;
        }
      }
    }



    // 67 : 33 row:
    .dgm-cols-6633{
      .dgm-col-66{
        .frame {
          margin-right: 0.75rem;
          margin-left: 0;
        }
      }

      .dgm-col-33{
        .frame, .contact--item {
          margin-left: 0.75rem;
          margin-right: 0;
        }
      }
    }





    // 33 : 67 row:
    .dgm-cols-3366{
      .dgm-col-33{
        .frame {
          margin-right: 0.75rem;
          margin-left: 0;
        }
      }

      .dgm-col-66{
        .frame {
          margin-left: 0.75rem;
          margin-right: 0;
        }

        & > .contact--item{
          margin-left: 0.75rem;
        }
      }
    }


    // col 50
    .dgm-cols-50{
      .dgm-col-50L{
        .frame {
          margin-right: 0.75rem;
          margin-left: 0;
        }
      }

      .dgm-col-50R{
        .frame {
          margin-left: 0.75rem;
          margin-right: 0;
        }
      }
    }



    .dgm-cols-2575{

      .dgm-col-75 .dgm-col-33 .frame{
        padding-left: 15%;
      }
    }
}

// adjust grey boxes 33er
div.dgm--grey-box{
  .dgm-cols-33 {
    .dgm-col-33L, .dgm-col-33C, .dgm-col-33R {
      .frame {
        height: 100%;
        box-sizing: border-box;

        &.frame-type-textpic {
          padding: 0;
          height: auto;

          & + .frame{
            height: auto;
          }
        }
      }
    }
  }
}


.dgm--lpgrid-item{

  margin-bottom: 3rem;


  h3{
    margin: 0;

    a{
      margin-bottom: 0;
      text-transform: none;
      padding: 3rem 1rem 2rem 1rem;
      color: #fff;
      background: #1c3259;
      display: block;
      text-align: center;
    }
  }

  .frame-type-textpic:hover h3 a{
    background: @red;
    color: @white;
  }
}


@media @mobile{

  .dgm-cols-33 .dgm-col-33L,
  .dgm-cols-33 .dgm-col-33C,
  .dgm-cols-3366 .dgm-col-33,
  .dgm-cols-6633 .dgm-col-66,
  .dgm-cols-50 .dgm-col-50L{
    margin-bottom: @colBtmSpacerMobile;
  }

  .dgm--lpgrid-item{
    margin-bottom: 0;

    .pure-u-lg-1-3{
      margin-bottom: 2rem;
    }
  }

}
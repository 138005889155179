.dgm-col-50L{

  // this is right, where the latest pdf is visible

  .dgm--pdf-item{
    display: flex;
    //background: @lightGrey;
    align-items: center;
    flex-wrap: wrap;

    strong{
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }

    .dgm--pdf-image{
      max-width: 400px;
      padding: 0;
      margin: 0;

      img{
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .dgm--pdf-data{
      display: flex;
      flex-wrap:wrap;
      width: 100%;
      padding: 20px 0;

      a{
        background: @lightGrey;
        color: @black;
        padding: 10px 20px;
        display: inline-block;
        .interactionTransition();

        & + a{
          margin-left: 40px;
        }

        &:hover{
          background: @blue;
          color: @white;
        }
      }
    }
  }
}






.dgm-col-50R{
  .dgm--pdf-item {
    display: flex;
    background: @lightGrey;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .dgm--pdf-image{
    max-width: 140px;
    padding: 20px;
    margin: 0;

    img{
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .dgm--pdf-data{
    padding-left: 50px;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 200px);
    box-sizing: border-box;

    strong{
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }

    a{
      background: @lightGrey;
      color: @black;
      padding: 10px 20px 10px 0;
      display: inline-block;
      .interactionTransition();

      & + a{
        margin-left: 20px;
      }

      &:hover{
        color: @blue;
      }
    }
  }
}
.frame-type-textpic{

  .ce-gallery[data-ce-columns="1"]{

    .ce-outer, .ce-inner, .ce-column, figure{
      position: static;
      width: 100%;
      height: auto;
      float: none;
    }

    img{
      width: 100%;
      height: auto;
      display: block;
    }
  }
}


.dgm-col-33{
  .frame-type-textpic .ce-gallery[data-ce-columns="1"] img{
    max-width: 100%;
  }
}
.news--archive{
  margin-top: 10rem;
}
.news--archive-title{
  color: @blue;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0.66rem 0;

  @media @mobile{
    font-size: 24px;
  }
}

.archive--items{
  margin: 2rem 0;

  h4{
    color: @blue;
    font-size: 24px;
    line-height: 1.1;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 0.66rem 0;
    cursor:pointer;

    @media @mobile{
      font-size: 20px;
    }
  }
}

.archive--item {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 4px dotted @blue;

  ul{
    display:none;
  }

  ul li {
    font-size: 1.25rem;
    list-style-type: square;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted @medLightGrey;

    a{
      color: @textGrey;

      &:hover{
        color: @red;
      }
    }


  }
}
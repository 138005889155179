

@stdFont:      "Roboto Condensed", Calibri, Helvetica, sans-serif;
@headlineFont: "Oswald", Calibri, Helvetica, sans-serif;

@maxWidth:    1170px;
@subHeaderHeight: 44px;
@navigationHeight: 57px;
@mobileHeaderHeight: 100px;

@hamburgerWidth:  36px;

@white:       #fff;
@lightGrey:   #f1f1f1;
@medLightGrey:#b6b6b6;
@medGrey:     #797979;
@textGrey:    #707070;
@darkGrey:    #222;
@black:       #000;


@blue:        #1c3259;
@red:         #ad182c;

@colBtmSpacerMobile: 2rem;


// breakpoints
@mobile: ~"screen and (max-width: 760px)";
@tablet: ~"screen and (max-width: 1023px)";

@minTabletLandscape: ~"screen and (min-width: 1024px)";
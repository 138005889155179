.page-menu{
  margin: 0;
  padding: 0;

  li{
    list-style-type: none;

    & + li{
      border-top: 2px dotted @medGrey
    }

    a{
      display: flex;
      min-height: 54px;
      width: 100%;
      align-items: center;
      color: @medGrey;
    }
  }
}

.dgm-col-33 .page-menu, .dgm-col-25 .page-menu{
  max-width: 80%;
}
.dgm--grey-box{
  .frame{
    background: @lightGrey;
    padding: 1.5rem 30px;
  }
}



a, .ui-widget-content div a{
  text-decoration: none;
  color: @red;
  .interactionTransition();

  &:hover{
    color: @blue;
  }
}


p{
  margin: 0 0 1rem 0;
}


.ce-table{
  border-collapse: collapse;

  &.ce-table-striped td{
    border-top: 0;
  }
}



.ce-gallery + .ce-bodytext{
  margin-top: 2rem;
}


.frame-layout-4{
  ul{
    margin: 1rem 0;
    padding: 0;

    li{
      margin: 0;
      list-style-type: none;

      a{
        display: block;
        padding: 0.5rem 1rem;
        background: #f8f8f8;
        color: @darkGrey;
        .interactionTransition();

        &:hover{
          color: @red;
        }
      }

      &:nth-child(even){
        a{
          background: #f0f0f0;
        }
      }
    }
  }
}
.dgm-col-66 .frame-type-form_formframework{
  max-width: 80%;

  @media @tablet{
    max-width: 100%;
    width: 100%;
  }
}


.frame-type-form_formframework{

  .frame.frame-default{
    margin-left: 140px;

    @media @mobile{
      margin-left: 0;
    }
  }

  .form-group{
    display: flex;
    margin-bottom: 1rem;

    label{
      width: 140px;

      @media @mobile{
        width: 100%;
      }
    }

    .input{
      width: calc(100% - 140px);

      @media @mobile{
        width: 100%;
      }

      input, textarea{
        font-family: @stdFont;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        border: 1px solid @medLightGrey;
        color: @textGrey;
        .interactionTransition();

        &:focus{
          color: @darkGrey;
          border-color: @darkGrey;
        }
      }

      textarea{
        min-height: 130px;
      }
    }



    .input.checkbox{
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding-left: 140px;

      @media @mobile{
        padding-left: 0;
      }

      .form-check{
        display: block;
      }
      .form-check-label{
        display: flex;
        width: 100%;

        input[type='checkbox']{
          width: 1rem;
          height: 24px;

          & + span{
            display: inline-block;
            padding-left: 1rem;
          }
        }
      }
    }
  }


  .actions{
    width: 100%;
    box-sizing: border-box;
    padding-left: 140px;
    margin-top: 2rem;

    .submit .btn{
      padding: 0.66rem 2rem;
      color: @white;
      background: @blue;
      .interactionTransition();

      &:active, &:hover{
        background: @red;
      }
    }

    @media @mobile{
      padding-left: 0;
    }
  }
}
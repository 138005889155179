.form-group{
  display: flex;
  margin-bottom: 20px;

  @media @mobile{
    flex-wrap: wrap;
  }


  label{
    width: 140px;

    & + input[type='text'],
    & + input[type='date'],
    & + input[type='time'],
    & + input[type='email'],
    & + input[type='url'],
    & + textarea,
    & + select{
      border: 1px solid @medLightGrey;
      border-radius: 0;
      height: 34px;
      font-family: @stdFont;
      font-size: 17px;
      line-height: 2;
      width: calc(100% - 140px);
      box-sizing: border-box;
      padding: 5px 10px;
      background: @white;

      @media @mobile{
        width: 100%;
      }
    }


    @media @mobile{
      width: 100%;
    }
  }


}
.dgm--accordion.ui-widget{
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: "Roboto Condensed", Calibri, Helvetica, sans-serif;
  color: @textGrey;

  .ui-widget-content{
    color: @textGrey;
    line-height: 1.5;
  }
}

.dgm--accordion.ui-accordion{
  .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    border: 0;
    border-radius: 0;
    padding: 0;

    &.ui-accordion-icons{
      //padding-right: 2rem;

      .ui-accordion-header-icon{
        position: absolute;
        top: 0.75rem;
        right: 0;
        background: url("../img/acc-arrow.jpg");
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        width: 18px;
        height: 9px;

        @media @mobile{
          top: 0.5rem;
        }
      }
    }

    &.ui-state-active{
      .ui-accordion-header-icon{
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }
    }


    &.ui-state-active,
    &.ui-state-default,
    &.ui-state-hover{
      background: @white;
    }


    @media @tablet{
      .like-h3{
        font-size: 1.5rem;
      }
    }

    @media @mobile{
      .like-h3{
        font-size: 1.25rem;
      }
    }
  }




  .ui-accordion-content{
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
  }
}
/* roboto-condensed-regular - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-italic - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v25-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-condensed-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700 - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/oswald-v49-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/oswald-v49-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-500 - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/oswald-v49-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/oswald-v49-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-500.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-600 - latin */
@font-face {
  font-display: auto; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/oswald-v49-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/oswald-v49-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
}



@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?apz7sf');
  src:  url('../fonts/icomoon.eot?apz7sf#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?apz7sf') format('truetype'),
  url('../fonts/icomoon.woff?apz7sf') format('woff'),
  url('../fonts/icomoon.svg?apz7sf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dgm--icon-"], [class*=" dgm--icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i.dgm--icon{
  margin-right:10px;
}
.dgm--icon-mail:before { content: '\e90b'; }
.dgm--icon-home:before { content: '\e90a'; }
.dgm--icon-login:before { content: '\e90c'; }
.dgm--icon-logout:before { content: '\e905'; }
.dgm--icon-doc-text:before { content: '\e904'; }
.dgm--icon-book-open:before { content: '\e907'; }
.dgm--icon-magic:before { content: '\e909'; }
.dgm--icon-simplybuilt:before { content: '\e906'; }
.dgm--icon-user-o:before { content: '\e90d'; }
.dgm--icon-search:before { content: '\e90f'; }
.dgm--icon-globe:before { content: '\e90e'; }
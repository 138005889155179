.footer--main{
  background: @blue;
  color: @white;
  padding-top: 40px;

  .footer--links{
    margin-top: 40px;
    border-top: 2px dotted @white;
    padding-top: 40px;
    padding-bottom: 2rem;
  }

  @media @tablet{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.footer--sub{
  background: @red;
  color: @white;
  height: 54px;
  font-size: 1.125rem;

  @media @mobile{
    font-size: 1rem;
  }


  .footer--sub-links{
    display: flex;
    margin-top: 7px;
    -webkit-justify-content: flex-end;
    justify-content: end;

    @media @mobile{
      margin-top: 0;
      justify-content: center;
    }
  }

  @media @tablet{
    text-align: center;
    padding: 2rem 1rem;

    .pure-g{
      display: flex;
      flex-direction: column-reverse;
    }

    .footer--sub-links{
      padding-bottom: 1rem;
      margin-bottom: 0;
      border-bottom: 3px dotted @white;
    }
  }

  @media @mobile{
    height: auto;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  a{
    color: @white;
    text-decoration: none;
    padding: 0.5rem;

    @media @minTabletLandscape{
      & + a{
        margin-left: 14px;
      }
    }
  }

  p{
    margin-bottom: 0;
    margin-top: 16px;

    @media @mobile{
      text-align: center;
    }
  }
}


.footer--social {

  .footer--socials-links{
    display: flex;
    width: 100%;
    min-height: 46px;
    justify-content: center;

    @media @mobile{
      flex-wrap: wrap;
    }
  }
  p {
    margin-bottom: 0;
  }

  a{
    //margin-top: 1rem;
    color: @white;
    text-decoration: none;
    display: inline-block;
    border: 1px solid @white;
    padding: 0.5rem 1rem;

    & + a{
      margin-left: 1rem;
    }

    @media @mobile{
      width: calc(50% - 4px);
      margin-bottom: 10px;
      box-sizing: border-box;

      & + a{
        margin-left: 0;
      }

      &:nth-child(even){
        margin-left: 4px;
      }
      &:nth-child(odd){
        margin-right: 4px;
      }
    }
  }

  .footer--socials-links a > span svg{
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
  }
  .footer--socials-links a:hover{
    border-color: @red;
    color: @white;
  }

  @media @tablet{
    text-align: center;
    padding-bottom: 2rem;
  }

  @media @mobile{
    padding-bottom: 20px;

    .pure-g{
      display: flex;
      -webkit-flex-direction: column-reverse;
      flex-direction: column-reverse;
      width: 100%;
    }


    .pure-u-lg-2-5{
      padding-bottom: 20px;
      border-bottom: 2px dotted @white;
      margin-bottom: 20px;
    }
  }
}





.footer--links{
  ul{
    padding: 0 3rem 0 0;
    margin: 0;

    li{
      list-style-type: none;
      padding: 0;

      a{
        color: @white;
        text-decoration: none;
      }
    }
  }

  @media @tablet{
    display: none;
  }
}
.interactionTransition(@dur: 0.33s; @props: all; @delay: 0s){
  //-webkit-transition: @for @dur ease-in-out;
  //-moz-transition: @for @dur ease-in-out;
  //transition: @for @dur ease-in-out;

  -webkit-transition-property: @props;
  -moz-transition-property: @props;
  transition-property: @props;

  -webkit-transition-duration: @dur;
  -moz-transition-duration: @dur;
  transition-duration: @dur;

  -webkit-transition-delay: @delay;
  -moz-transition-delay: @delay;
  transition-delay: @delay;

  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
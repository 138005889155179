.contact--item{

  @media @mobile{
    margin-bottom: 40px;
  }

  .contact--line{
    display: flex;
    width: 100%;

    @media @mobile{

      &.contact--line-phone,
      &.contact--line-mail,
      &.contact--line-mobile{
        margin: 2px 0;
        align-items: center;
      }
    }
  }

  .contact--label{
    width: 60px;
  }

  .contact--entry a{
    @media @mobile{
      display: block;
      padding: 9px 0;
    }
  }

  & + .contact--item, & + .frame{
    margin-top: 2rem;
  }
}

@media @minTabletLandscape{
  .dgm-cols-50 .dgm-col-50R .contact--item{
    margin-left: 0.75rem;
  }
}


.accordion-content{
  .contact--item{
    margin-bottom: 1.5rem;
  }
}
.dgm--metanav{
  display: flex;
  align-items: center;

  .dgm--meta-breadcrumbs{
    width: 67%;
    display: flex;
    -webkit-align-items: baseline;
    align-items: baseline;

    @media @mobile{
      display: none;
    }

    .dgm--breadcrumbs{
      margin: 0;
      padding: 0;
      display: flex;

      li{
        list-style-type: none;
        margin: 40px 0;

        &.dgm--breadcrumbs-current{
          font-style: italic;
          color: @textGrey;
        }
      }
    }

    .dgm--breadcrumbs-spacer{
      margin-right: 0.5rem;
    }

    a{
      font-style: italic;
      text-decoration: underline;
      color: @textGrey;
      margin-right: 0.5rem;
      .interactionTransition();

      &:hover{
        color: @red;
      }
    }
  }



  .dgm--meta-actions{
    width: 33%;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: end;

    button{
      padding: 8px;

      & + button{
        margin-left: 10px;
      }
    }

    @media @mobile{
      padding: 20px 0;
      width: 100%;
    }
  }
}
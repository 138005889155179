main{
  min-height: 800px;
  max-width: 1170px;
  margin: auto;
  padding: 257px 0 80px 0;
  .interactionTransition();

  @media @mobile{
    overflow-x: hidden;
  }


  @media @tablet{
    padding: 4rem 1.5rem 6rem 1.5rem;
  }

  @media @mobile{
    padding: 0 1rem 3rem 1rem;
  }
}

.scrolled main{
  padding-top: 167px;

  @media @tablet{
    padding: 4rem 1.5rem 6rem 1.5rem;
  }

  @media @mobile{
    padding: 0 1rem 3rem 1rem;
  }
}
.search--wrapper{
  position: absolute;
  z-index: 745;
  top: 0;
  width: 100%;
  height: @subHeaderHeight;
  background: @white;
  opacity: 0;
  visibility: hidden;
  .interactionTransition();

  @media @mobile{
    position: fixed;
    z-index: 780;
  }

  .search--container{
    width: 100%;
    max-width: @maxWidth;
    height: 100%;
    margin: auto;
    position: relative;
  }

  .search{
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 5px;
    border: 1px solid @medGrey;
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
    background: @white;
    display: flex;

    input{
      border: 0;
      padding: 0 0 0 1rem;
      background: transparent;
      box-shadow: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      outline: 0;
      display: block;
      line-height: 20px;
      flex:auto;
      font-family: @stdFont;
      color: @textGrey;
    }

    button{
      width: 66px;
      height: 100%;
      background: transparent;
      border-left: 1px solid @medGrey;
      cursor: pointer;
      color: @textGrey;
    }


    @media @mobile{
      max-width: unset;
      width: unset;
      top: 25px;
      right: 1rem;
      bottom: 25px;
      left: 1rem
    }
  }

  @media @mobile{
    height: @mobileHeaderHeight;
  }
}

.search--active .search--wrapper{
  opacity: 1;
  visibility: visible;
}
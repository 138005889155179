.slider--wrapper {
  width: 100%;
  height: auto;
  //max-height: 540px;
  position: relative;
  background: #f0f0f0;

  &::before {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    background: #f0f0f0;
    content: "";
  }

  &::after {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    background: #f0f0f0;
    content: "";
  }


  @media @tablet {
    &::before, &::after {
      display: none;
    }

    max-height: unset;
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }

}

.slider--content{
  padding: 40px 0;

  @media @mobile{
    padding: 0;
  }


  .swiper {
    width: 100%;
    height: auto;
    background: #fff;

    .ce-above .ce-gallery{
      margin-bottom: 0;
    }
  }

  .swiper-slide .frame-type-textpic .ce-gallery .image{
    position: relative;

    figcaption{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      padding: 0.5rem 2rem;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
      color: #eee;
      font-style: italic;
      text-align: center;
    }
  }


  .swiper-button-next::after, .swiper-button-prev::after{
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
  }

  .swiper-button-prev{
    left: 0.5rem;
    bottom: 0;
    top: unset;
  }

  .swiper-button-next{
    right: 0.5rem;
    bottom: 0;
    top: unset;
  }
}



.slider--home{
  padding-top: 40px;

  .frame{
    position: relative;

    header{
      position: absolute;
      z-index: 340;
      bottom: 85px;
      left: 50px;
      right: 33%;
      height: auto;

      h2{
        font-size: 24px;
        font-weight: 600;
        color: @white;
        text-transform: unset;
        text-shadow: 0 0 8px rgba(0,0,0,0.8);
        margin-bottom: 15px;

        a{
          color: @white;

          @media @mobile{
            color: @blue;
          }
        }
      }
      h3{
        font-size: 32px;
        line-height: 1.5;
        font-family: @headlineFont;
        font-weight: 600;
        color: @white;
        text-shadow: 0 0 8px rgba(0,0,0,0.8);
        margin-bottom: 15px;

        a{
          color: @white;

          @media @mobile{
            color: @blue;
          }
        }
      }
    }

    .ce-gallery{
      margin-bottom: 0!important;;
      .image::after{
        position: absolute;
        z-index: 320;
        background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
        height: 40%;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";

        @media @tablet{
          display: none;
        }
      }
    }
    .ce-bodytext{
      position: absolute;
      bottom: 60px;
      left: 50px;
      color: #fff;
      z-index: 350;
      font-size: 18px;

      p{
        margin: 0;
      }

      a{
        color: @white;
      }
    }



    @media @tablet{
      display: flex;
      flex-direction: column;
      width: 100%;
      position: static;

      .ce-textpic{
        width: 100%;
        order: 1;
        p{
          margin-bottom: 0;
        }
      }

      .ce-gallery{
        margin-bottom: 0;
      }

      header{
        width: 100%;
        order: 2;
        background: @white;
        color: @darkGrey;
        position: static;
        box-sizing: border-box;
        padding: 1rem 1rem 3rem 1rem;

        h2, h3{
          text-shadow: none;
        }
        h2{
          font-size: 20px;
          text-transform: unset;
          color: @textGrey;
          font-weight: 500;
        }
        h3{
          font-size: 22px;
          color: @blue;
        }
      }
      .ce-bodytext{
        width: 100%;
        order: 3;
        background: @white;
        color: @darkGrey;
        position: static;
        display: none;
      }
    }
  }


  .swiper--interaction{
    height: 80px;
    padding: 33px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;


    .swiper-pagination{
      position: static;
      width: auto;

      .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        border-radius: 0;
        background: @medLightGrey;
        margin: 0 10px;

        &.swiper-pagination-bullet-active{
          background: @textGrey;
        }
      }
    }

    .swiper-button-next, .swiper-button-prev{
      position: static;
      width: 16px;
      height: 14px;
      margin: 0 10px;
      font-size: 13px;
      color: @white;

      &::after{
        font-size: 13px;
        color: @medLightGrey;
      }
    }
  }

  .dgm--button-main{
    position: absolute;
    bottom: 72px;
    right: 50px;
    font-size: 1.25rem;
    z-index: 500;
  }

}
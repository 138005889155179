#germanymap{
  width: 100%;
  max-width: 600px;
  height: auto;

  path{
    .interactionTransition();
  }

  a:hover path{
    fill: @red;
  }
}
#fp_mathcaptcha{
  width: 120px;
  margin-right: 20px;
}

.form-group{
  label + .form-control-checkbox{
    width: calc(100% - 140px);

    @media @mobile{
      width: 100%;
    }
  }
}

#fp_gdpr{
  width: 20px;
  height: 20px;
  border: 1px solid @medLightGrey;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 10px;

  &:checked{
    background: @medLightGrey;
    box-shadow: inset 0 0 0 3px rgba(255,255,255,1);
  }
}


.tx-fp-newsletter .btn.btn-sm.btn-secondary{
  margin-left: 140px;
  padding: 10px 20px;
  color: @white;
  background: @red;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  .interactionTransition();

  &:hover{
    background: @blue;
  }

  @media @mobile{
    margin-left: 0;
  }
}
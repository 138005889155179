.navigation{
  position: fixed;
  z-index: 720;
  top: 244px;
  left: 0;
  right: 0;
  background: @white;
  .interactionTransition();

  .container{
    display: flex;
    justify-content: right;
    position: relative;
  }

  .pure-menu{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: right;
    font-family: @headlineFont;
    font-weight: 500;
  }

  .pure-menu-list{
    height: @navigationHeight;
    position: static;

    .pure-menu-has-children > .pure-menu-link::after{display: none;}

    .pure-menu-link{
      padding: 0 1.5rem;
      margin: 9px 0 9px 0;
      //height: 100%;
      line-height: 36px;
      font-size: 1.25rem;
      font-weight: 500;
      text-transform: uppercase;
      color: @blue;

      &:hover{
        color: @red;
        background: transparent;
      }


      @media @minTabletLandscape{

        &::before{
          position: absolute;
          bottom: -19px;
          left: calc(50% - 7px);
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-bottom: 9px solid @white;
          content: "";
          displaY: block;

          opacity: 0;
          visibility: hidden;
          -webkit-transform: translate3d(0,1rem,0);
          transform: translate3d(0,1rem,0);
          .interactionTransition();
        }
      }
    }

    .submenu--open > .pure-menu-link{
      color: @red;
      position:relative;

      @media @minTabletLandscape{

        &::before{
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }
      }
    }

    & > li{
      margin: 0;
      height: 67px;
      position: static;

      &:hover{
        //height: 67px;
      }
    }


    & > li + li{
      > a{
        border-left: 3px dotted @blue;
      }
    }
  }

  .pure-menu-horizontal .pure-menu-children{
    right: 0;
    top: calc(100% + 10px);
    border-top: 10px solid rgba(0,0,0,0);
    padding: 22px 30px 20px 30px;
    background: @white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0,1rem,0);
    transform: translate3d(0,1rem,0);
    .interactionTransition();

    a{
      font-size: 1.125rem;
      color: @textGrey;
      font-family: @stdFont;
      text-transform: none;
      font-weight: 400;
      padding-left: 0;
      display: flex;
      align-items: center;
      width: 100%;
    }

    li{
      height: 52px;
      width: 30%;
      border-bottom: 2px dotted @medLightGrey;
/*
      &:nth-last-child(-n+3){
        @media @minTabletLandscape{
          border-bottom: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3){
        border-bottom: 2px dotted @medLightGrey;
      }
*/

    }
  }

  .pure-menu-allow-hover:hover > .pure-menu-children,
  .submenu--open .pure-menu-children{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .submenu--trigger,
  .subsubmenu--trigger,
  .mobile--system-nav{
    display: none;
  }
}


// get the menu trigger buttons for tablets back
@media screen and (min-width: 980px) and (max-width: 1140px){

  .is--tablet .navigation {

    .pure-menu-horizontal .pure-menu-list{
      display: flex;
      width: 100%;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      justify-content: end;
    }

    .pure-menu-has-children{
      display: flex;

      a{
        padding-right: 4px;
      }
    }
    .submenu--trigger {
      height: 50px;
      width: 36px;
      background: transparent;
      display: inline-block;
      bottom: 9px;


      svg{
        width: 11px;
        height: auto;
        fill: @textGrey;
      }
    }
  }


}





@media @mobile{

  // disable dropdown arrows
  .pure-menu-horizontal .pure-menu-has-children > .pure-menu-link::before,
  .pure-menu-horizontal .pure-menu-has-children > .pure-menu-link::after{
    display: none;
  }



  .navigation{
    position: fixed;
    top: @mobileHeaderHeight;
    left: 0;
    right: 0;
    height: auto;
    z-index: 710;
    background: @white;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0,2rem,0);
    transform: translate3d(0,2rem,0);
    .interactionTransition();

    .container{
      flex-wrap:wrap;
    }

    .pure-menu{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: right;
    }

    .pure-menu-list {
      height: auto;
      position: static;
      width: 100%;

      li{
        display: block;
        padding: 0;
        margin: 0;

        & + li{
          border: 0;

          .pure-menu-link {
            border-top: 2px dotted @medLightGrey;
            border-left: 0;
          }
        }
      }

      & > li{
        height: 44px;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      & > .pure-menu-has-children{
        position: relative;
        overflow: hidden;
      }

      .pure-menu-link{
        line-height: 44px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0;
      }
    }

    .submenu--trigger{
      position: absolute;
      top: 0;
      right: 1rem;
      width: 44px;
      height: 44px;
      text-align: center;
      display: block;

      svg{
        width: 14px;
        height: auto;
        fill: @darkGrey;
      }
    }

    .submenu--open > .submenu--trigger > svg{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .pure-menu-horizontal .pure-menu-children{
      display: block;
      position: static;
      border-top: 0;
      justify-content: unset;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      border-top: 2px dotted @medLightGrey;

      & > li{
        display: block;
        width: 100%;
        height: 44px;
        border-bottom: 2px dotted @medLightGrey;

        &:last-child{
          border-bottom: 0;
        }

        .pure-menu-link{
          border: 0;
          padding-left: 1rem;
          box-sizing: border-box;
          margin-right: 0;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6){
            border-top: 0
        }

        & + li{
          .pure-menu-link{
            //border-top: 3px dotted @medGrey;
          }
        }
      }
    }

    .submenu--open.pure-menu-has-children{
      height: auto;

      .subsubmenu--trigger{
        position: absolute;
        top: 0;
        right: 0;
        width: 44px;
        height: 44px;
        text-align: center;
        display: block;

        svg{
          width: 14px;
          height: auto;
          fill: @darkGrey;
        }
      }

      .submenu--open > .subsubmenu--trigger > svg{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      & > .pure-menu-children{
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
      }
    }





    .pure-menu-horizontal .pure-menu-children {
      .pure-menu-has-children{
        overflow: hidden;

        &.submenu--open{
          background: #f1f1f1;
        }
      }

      // this is thrid level menu ul
      .pure-menu-children {
        background: transparent;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translate3d(0,2rem,0);
        transform: translate3d(0,2rem,0);

        li .pure-menu-link{
          padding-left: 2rem;
        }
      }

      .submenu--open{
        height: auto;

        .pure-menu-children {
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .navigation--active .navigation{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }



  .navigation .mobile--system-nav{
    display: block;
    width: 100%;
    padding: 1rem 0 2rem 0;
    margin: 0 1rem;
    border-top: 2px dotted @medLightGrey;
    box-sizing: border-box;

    button, a{
      display: flex;
      width: 100%;
      height: 42px;
      padding-left: 1rem;
      box-sizing: border-box;
      align-items: center;
      color: @medGrey;
      font-size: 1.25rem;
      text-decoration: none;

      span::before{
        margin-right: 1rem;
      }
    }
  }
}
.header{
  background: @blue;
  height: 200px;
  position: fixed;
  z-index: 700;
  top: 44px;
  left: 0;
  right: 0;
  .interactionTransition();

  .container{
    height: 100%;
    .interactionTransition();
  }

  a{
    display: inline-block;

    @media @mobile{
      height: 100%;
      display: block;
      width: auto;
      padding-left: 1rem;
      box-sizing: border-box;
      padding-top: 18px;
      padding-bottom: 5px;
    }

    img{
      display: block;
      max-width: 100%;
      height: 163px;
      width: auto;
      margin-bottom: 0;
      margin-top: 20px;
      .interactionTransition();

      @media @mobile{
        max-width: 220px;
        height: auto;
        width: 100%;
        margin-top: 0;
      }
    }
  }

  #search--trigger_mobile,
  #hamburger{
    display: none;
  }





  @media @mobile{


    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 720;

    .container{
      display: flex;
      position: relative;
      height: 100%;
      align-items: center;
    }




    #search--trigger_mobile{
      display: block;
      width: 60px;
      height: 60px;
      background: transparent;
      color: @white;
      position: absolute;
      top: 50%;
      right: 60px;
      margin-top: -30px;
      font-size: 1.5rem;
    }
  }
}



// header is sticky - on desktop after 45px scroll depth
@media @minTabletLandscape{
  .scrolled{
    .header{
      top: 0;
      height: 110px;

      img{
        height: 90px;
        width: auto;
        margin-top: 10px;
      }
    }

    .navigation{
      top: 110px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.6);
    }
  }

}
.dgm--cal-filter{
  padding: 17px;
  margin-bottom: 20px;
  border-bottom: 2px dotted @blue;

  label{
    padding-right: 1rem;
  }

  select{
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    border: 1px solid @medLightGrey;
  }
}

.dgm--calitem{
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 2px dotted @blue;
  margin-left: 0.75rem;
  position:relative;

  .dgm--advert-actionbtn{
    position: absolute;
    top: 0;
    width: 80px;
    text-align: center;
    padding: 5px;

    &.dgm--advert-edit{
      right: 100px;
      background: @blue;
      color: @white;
    }

    &.dgm--advert-delete{
      right: 0;
      background: @red;
      color: @white;
    }
  }

  @media @mobile{
    flex-wrap: wrap;
    margin-left: 0;
  }

  .dgm--calitem-image{
    max-width: 300px;
    height: auto;
    margin-left: 2rem;

    img{
      width: 100%;
      height: auto;
    }

    @media @mobile{
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  .dgm--debug{
    position: absolute;
    top: -0.75rem;
    opacity: 0.2;
    font-size: 14px;
    display: none;
  }

  &.dgm--calitem-hidden{
    display: none;
  }


  .dgm--calitem-title{
    margin-bottom: 17px;
  }

  .dgm--calitem-date{
    margin-top: -7px;
    color: @darkGrey;
    margin-bottom: 17px;
    font-weight: 600;
    display: block;
  }

  .dgm--calitem-text{
    margin-bottom: 7px;
    color: @textGrey;
  }

  .dgm--calitem-location{
    color: @textGrey;
    font-size: 14px;
  }
}
#c1901{
  .ce-image{
    .ce-outer, .ce-inner{
      position: static;
      float: none;
      width: 100%;
    }

    .ce-row{
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media @mobile{
        -webkit-flex-wrap:wrap;
        flex-wrap:wrap;
      }


      .ce-column{
        margin: 0;
        float: none;
        display: block;

        figure{
          display: block;

          img{
            width: 100%;
            height: auto;
            margin: 0;
          }
        }


        @media @mobile{

          &:first-child{
            width: 33%;
            margin-bottom: 1.5rem;

            figure{
              padding-right: 0.75rem;
            }
          }
          &:nth-child(2){
            width: 67%;
            margin-bottom: 1.5rem;

            figure{
              padding-left: 0.75rem;
            }
          }

          &:nth-child(3){
            width: 45%;

            figure{
              padding-right: 0.75rem;
            }
          }
          &:last-child{
            width: 55%;

            figure{
              padding-left: 2.75rem;
            }
          }
        }
      }
    }
  }
}
#login{
  width: 100%;
  max-width: 740px;
  position: fixed;
  top: 55px;
  right: 50%;
  margin-right: calc((@maxWidth / 2) * -1);
  background: @white;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.8);
  z-index: 750;

  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0,2rem,0);
  transform: translate3d(0,2rem,0);
  .interactionTransition();
}

.loginform--active #login{
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

#login--trigger{
  cursor: pointer;
}

.login--inner{
  padding: 2rem;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.login--area{
  width: 58%;
  padding-right: 2rem;
  border-right: 2px dotted @medGrey;
}
.register--area{
  width: 42%;
  padding-left: 2rem;
}


.login--heading{
  color: @blue;
  text-transform: uppercase;
  font-weight: 500;
}

#register--now{
  padding: 0.5rem 1rem;
  background: @red;
  display: inline-flex;
  margin-top: 2rem;
  color: @white;
  text-decoration: none;
}

.frame-type-felogin_login{
  h3{
    font-size: 20px;

    & + p{
      display: none;
    }
  }

  fieldset{
    border: 0;
    padding: 0;
    margin: 0;

    legend{
      display: none;
    }

    label{
      display: block;
      margin-bottom: 20px;

      input{
        display: block;
        width: 100%;
        padding: 7px 1rem;
        box-sizing: border-box;
      }
    }

    input[type='submit']{
      font-family: @headlineFont;
      padding: 0.5rem 1rem;
      background: @red;
      display: inline-flex;
      margin-top: 0;
      color: @white;
      text-decoration: none;
      border: 0;
    }
  }
}
#overlay{
  position: fixed;
  z-index: 730;
  background: rgba(0,0,0,0.6);

  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}

.overlay--active{
  #overlay{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.overlay--from-intern,
  &.overlay--from-login{
    #overlay{
      top: 44px;
    }
  }


  &.overlay--from-navigation{
    #overlay{
      top: 301px;
      z-index: 700;
    }
  }
  &.scrolled.overlay--from-navigation{
    #overlay{
      top: 160px;
      z-index: 700;
    }
  }


  &.overlay--from-hamburger{
    #overlay{
      z-index: 700;
    }
  }
}
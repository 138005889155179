/* -----------------------------------------------------------

HAMBURGER Styles

----------------------------------------------------------- */
@media @mobile{
  .header #hamburger{
    display: block;
    width: 60px;
    height: 60px;
    background: rgba(255,255,255,0.2);
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -30px;
    padding: 5px 0 5px 5px;
    cursor:pointer;
    transition-timing-function:linear;
    transition-duration:.15s;
    transition-property:opacity,-webkit-filter;
    transition-property:opacity,filter;
    transition-property:opacity,filter,-webkit-filter;
    text-transform:none;
    color:inherit;
    border:0;
    background-color:transparent;
  }
  .hamburger--box {
    position:relative;
    display:inline-block;
    width:@hamburgerWidth;
    height:20px
  }
  .hamburger--inner {
    top:50%;
    display:block;
    margin-top:-2px
  }
  .hamburger--inner,.hamburger--inner:after,.hamburger--inner:before {
    position:absolute;
    width:@hamburgerWidth;
    height:2px;
    transition-timing-function:ease;
    transition-duration:.15s;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform,-webkit-transform;
    border-radius:2px;
    background-color:@white;
  }

  .hamburger--inner:after,.hamburger--inner:before {
    display:block;
    content:''
  }
  .hamburger--inner:before {
    top:-10px;
  }
  .hamburger--inner:after {
    bottom:-10px
  }




  .header #hamburger {

    .hamburger--inner {
      transition-timing-function: cubic-bezier(.55, .055, .675, .19);
      transition-duration: .1s;

      &::before {
        transition: top .1s .14s ease, opacity .1s ease
      }

      &::after {
        transition: bottom .1s .14s ease, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19);
        transition: bottom .1s .14s ease, transform .1s cubic-bezier(.55, .055, .675, .19);
        transition: bottom .1s .14s ease, transform .1s cubic-bezier(.55, .055, .675, .19), -webkit-transform .1s cubic-bezier(.55, .055, .675, .19)
      }
    }
  }

  .navigation--active .header #hamburger{

    .hamburger--inner {
      transition-delay:.14s;
      transition-timing-function:cubic-bezier(.215,.61,.355,1);
      -webkit-transform:rotate(45deg);
      transform:rotate(45deg);

      &::before{
        top:0;
        transition:top .1s ease,opacity .1s .14s ease;
        opacity:0;
      }

      &::after{
        bottom:0;
        transition:bottom .1s ease,-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
        transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1);
        transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1),-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
        -webkit-transform:rotate(-90deg);
        transform:rotate(-90deg)
      }

    }


  }

}




.video--wrapper{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

figure.video--wrapper{
  margin: 0 0 90px 0;
  width: 100%;

  figcaption{
    position: absolute;
    top: 100%;
    padding-top: 17px;
    line-height: 1.2;
    font-size: 18px;
    font-weight: 600;
    left: 0;
    right: 0;

    small{
      font-size: 16px;
      font-weight: 400;
    }
  }
}
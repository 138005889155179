.dgm--advert{
  background: @lightGrey;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  .dgm--advert-actionbtn{
    position: absolute;
    top: 0;
    width: 80px;
    text-align: center;
    padding: 5px;

    &.dgm--advert-edit{
      left: 0;
      background: @blue;
      color: @white;
    }

    &.dgm--advert-delete{
      left: 100px;
      background: @red;
      color: @white;
    }
  }

  h3{
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    color: @blue;
    line-height: 1.2;
    margin-top: 0;

    @media @mobile{
      margin-top: 20px;
    }
  }

  .dgm--advert-inner{
    display: flex;
    width: 100%;

    @media @mobile{
      flex-wrap:wrap;
    }

    .dgm--advert-mainimg{
      width: 33%;

      @media @mobile{
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .dgm--advert-content{
      width: 67%;
      position: relative;

      @media @mobile{
        width: 100%;
      }
    }
  }



  .dgm--advert-mainimg_item{
    margin: 0 30px 0 0;
    min-height: 16vw;
    max-height: 240px;
    background: @white url("../img/noimg.jpg") no-repeat center center;

    img{
      display: block;
      width: 100%;
      height: auto;
    }

    @media @mobile{
      min-height: 120px;
      margin: 0;
    }
  }


  .dgm--advert-type{
    position: absolute;top: 0;
    right: 0;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
  }

  .dgm--advert-furtherimgs{
    display: flex;
    margin-top: 20px;

    @media @mobile{
      flex-wrap:wrap;
    }

    .dgm--furtherimg-item{
      height: 100px;

      @media @mobile{
        height: 60px;
        margin-bottom: 10px;
        margin-right: 10px;
      }

      & + .dgm--furtherimg-item{
        margin-left: 10px;

        @media @mobile{
          margin-left: 0;
        }
      }

      & img {
        display: block;
        height: 100%;
        width: auto
      }
    }
  }


  .dgm--advert-price {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: @red;
    text-transform: uppercase;
  }

  .dgm--advert-contacts{

    .dgm--advert-contactline{
      display: flex;

      .dgm--advert-label{
        width: 70px;
      }
    }
  }
}






#advertForm, #calForm{
  max-width: 66%;

  @media @mobile{
    max-width: 100%;
    width: 100%;
  }

  .dgm--checkboxes{
    display: flex;

    label{
      line-height: 20px;
      display: flex;
      align-items: center;
    }

    input[type='radio']{
      width: 20px;
      height: 20px;
      border: 1px solid @medLightGrey;
      border-radius: 0;
      -webkit-appearance: none;
      appearance: none;
      margin-right: 10px;

      &:checked{
        background: @medLightGrey;
        box-shadow: inset 0 0 0 3px rgba(255,255,255,1);
      }
    }
  }


  .dgm--upload-img{
    margin-bottom: 40px;


    #uploadcontainer{
      border: 1px solid @medLightGrey;
      padding: 10px;
      display: flex;
      width: calc(100% - 140px);

      @media @mobile{
        width: 100%;
      }
    }
    #fakeupload{
      width: 100px;
      height: 70px;
      display: block;
      border: 1px solid @medLightGrey;
      overflow: hidden;
      text-indent: 200px;
      background: url("../img/upload-grafik.jpg") no-repeat center center;
      background-size: contain;
      cursor: pointer;

      span{
        opacity: 0;
      }
    }
    #fileupload{
      width: 70px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      position: static;
    }

    #openFile{
      display: none;
    }

    #imageTable{
      display: flex;
      width: calc(100% - 220px);

      img{
        width: 80px;
        height: auto;
        margin-right: 5px;

        & + a{
          display: block;
          width: 16px;
          height: 16px;
          background: rgba(0,0,0,0.7);
          line-height: 14px;
          text-align: center;
          margin-left: -21px;
          color: white;
        }
      }
    }
  }

  .dgm--advert-subline{
    margin-top: 60px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .form-group{
    display: flex;
    margin-bottom: 20px;

    &.has--space-after{
      margin-bottom: 40px;
    }

    @media @mobile{
      flex-wrap: wrap;
    }

    label{
      width: 140px;

      @media @mobile{
        width: 100%;
      }

      & + select,
      & + input,
      & + textarea{
        border: 1px solid @medLightGrey;
        border-radius: 0;
        height: 34px;
        font-family: @stdFont;
        font-size: 17px;
        line-height: 2;
        width: calc(100% - 140px);
        box-sizing: border-box;
        padding: 5px 10px;
        background: @white;

        &.dgm--input-short{
          max-width: 240px;
        }

        @media @mobile{
          width: 100%;
        }
      }

      & + textarea{
        margin: 0;
        min-height: 170px;
      }
    }


    .dgm--submit-btn{
      margin-left: 140px;
      margin-top: 30px;
      padding: 10px 20px;
      background: @blue;
      border: 0;
      border-radius: 0;
      cursor: pointer;
      color: @white;
      font-weight: 500;
      font-family: @stdFont;

      @media @mobile{
        margin-left: 0;
      }
    }
  }


  .dgm--advert-legal{
    padding-left: 140px;

    @media @mobile{
      padding-left: 0;
    }

    p em{
      display: block;
      margin-bottom: 10px;
    }

    .dgm--legal-line {
      label{
        display: flex;
        width: 100%;
        align-items: center;
      }
      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        border: 1px solid @medLightGrey;
        border-radius: 0;
        -webkit-appearance: none;
        appearance: none;
        margin-right: 10px;

        &:checked {
          background: @medLightGrey;
          box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 1);
        }
      }
    }
  }

}
.dgm--reset-form{
  border-top: 2px dashed @lightGrey;
  padding: 30px 0 30px 140px;
  border-bottom: 2px dashed @lightGrey;
  margin: 10px 0;

  @media @mobile{
    padding: 10px 0;
  }

  input, button{
    border-radius: 0;
    border: 0;
    background: @medLightGrey;
    color: @black;
    padding: 5px;
    font-size: 14px;
  }
}

.advert--legal{
  margin-top: 5rem;
}
button,
.like--a-link{
    border: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

.dgm--button-main{
    background: @red;
    padding: 0.66rem 1.5rem;
    color: @white;
    display: inline-block;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);

    &:hover,
    &:active{
        background: @blue;
        color: @white;
    }

    .dgm--button-inner{
        display: block;
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }
}
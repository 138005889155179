#intern{
  position: absolute;
  top: calc(100% + 17px);
  width: 240px;
  left: 50%;
  margin-left: -120px;
  background: @white;
  box-shadow: 0 0 2px rgba(0,0,0,0.7);
  z-index: 770;

  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0,2rem,0);
  transform: translate3d(0,2rem,0);
  .interactionTransition();

  &::before{
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom: 11px solid @white;
    position: absolute;
    top: -17px;
    left: calc(50% - 11px);
    content: "";
  }

  .intern--inner{
    padding: 20px 30px;
    font-size: 1.125rem;
  }

  ul{
    margin: 0;
    padding: 0;

    li +li{
      border-top: 2px dotted @medLightGrey;
    }

    a{
      display: flex;
      width: 100%;
      height: 54px;
      align-items: center;
      color: @medGrey;
      text-decoration: none;
      padding: 0;
      .interactionTransition();

      &:hover,
      &:active{
        color: @red;
      }
    }
  }
}

.internnav--active #intern{
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
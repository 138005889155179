h1{
  color: @blue;
  font-family: @headlineFont;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 0;

  @media @mobile{
    font-size: 24px;
  }
}

.dgm--dotted-headline{
  margin: 66px 0 40px 0;
  position: relative;

  h2, h3, .like-h3{
    position: relative;
    font-size: 30px;
    font-weight: 600;
    font-family: @headlineFont;
    margin: 1rem 0 2rem;

    &::before{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
      height: 0;
      border-bottom: 4px dotted @blue;
      content: "";
      display: block;
    }

    @media @mobile{
      font-size: 24px;
    }
  }

  h3, .like-h3{
    font-size: 2rem;

    &::before{
      bottom: 7px;
      border-bottom: 3px dotted @blue;

      @media @mobile{
        bottom: 2px;
      }
    }
  }

  .dgm--header-inner{
    padding: 0 8px 0 0;
    display: inline-block;
    background: @white;
    position: relative;
  }
}

.dgm--grey-box .dgm--dotted-headline .dgm--header-inner{
  background: @lightGrey;
}

h2{
  color: @blue;
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0;
  font-family: @headlineFont;

  a{
    color: @blue;
    .interactionTransition();

    &:hover{
      color: @red;
    }
  }

  @media @mobile{
    font-size: 24px;
  }
}


h3, .like-h3{
  color: @blue;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  font-family: @headlineFont;
  //text-transform: uppercase;
  margin: 0 0 17px 0;

  a{
    color: @blue;
    .interactionTransition();

    &:hover{
      color: @red;
    }
  }

  @media @mobile{
    font-size: 20px;
  }
}

.ui-widget-content div h3 a{
  color: @blue;
  .interactionTransition();

  &:hover{
    color: @red;
  }
}

* + h3{
  margin-top: 2rem;
}
.page--wrapper{
  min-height: 800px;
  overflow-x: hidden;

  @media @mobile{
    // equalize the fixed header height
    padding-top: @mobileHeaderHeight;
  }
}

.container{
  width: 100%;
  max-width: @maxWidth;
  margin: auto;
}